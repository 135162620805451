import domReady from '@wordpress/dom-ready';
import Swiper from 'swiper';
import { _x } from '@wordpress/i18n';

domReady(() => {
  const carouselBlocks = document.querySelectorAll('.involvements-carousel');

  if (0 < carouselBlocks.length) {
    carouselBlocks.forEach((carouselBlock) => {
      new Swiper(carouselBlock.querySelector('.swiper'), {
        slidesPerView: 1.2,
        loop: true,
        spaceBetween: 16,
        rewind: true,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },

        navigation: {
          prevEl: carouselBlock.querySelector('.swiper-button-prev'),
          nextEl: carouselBlock.querySelector('.swiper-button-next'),
        },

        breakpoints: {
          768: {
            slidesPerView: 2.16,
            spaceBetween: 32,
          },
        },

        a11y: {
          firstSlideMessage: _x(
            'This is the first slide',
            'carousel first slide label',
            'vtx'
          ),
          lastSlideMessage: _x(
            'This is the last slide',
            'carousel first slide label',
            'vtx'
          ),
          paginationBulletMessage: _x(
            'Go to slide {{index}}',
            'carousel navigation label',
            'vtx'
          ),
          prevSlideMessage: _x(
            'Previous slide',
            'carousel previous label',
            'vtx'
          ),
          nextSlideMessage: _x('Next slide', 'carousel next label', 'vtx'),
        },
      });
    });
  }
});
